.title {
  padding: 20px 0;
  color: #fff;
  font-size: x-large;
}

.container {
  // background-color: #141414;
  margin-top: 20px;
  // display: flex;
  // padding: 20px;
  // @apply w-full grid grid-cols-1   lg:grid-cols-8;
  width: 100%;
  @media screen and (max-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .leftContainer {
    // @apply   lg:col-span-6;

    padding: 0 0px;
    width: 100%;
    .moduleTop {
      display: flex;
      color: #fff;
      align-items: center;
      overflow: hidden;
      .moduleBtn {
        @apply flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 4px;
        .moduleName {
          font-size: 20px;
          padding: 10px 0;
        }

        .more {
          display: flex;
          align-items: center;
          font-size: 14px;
        }
        img {
          width: 16px;
          height: 16px;
          transform: rotate(180deg);
        }
        @media screen and (min-width: 768px) {
          justify-content: left;
          width: auto;

          .moduleName {
            padding: 20px 0;
            font-size: 24px;
            overflow: hidden;
            background-color: #141414;
          }
          &:hover {
            cursor: pointer;
            color: #5fb878;

            .show {
              display: flex;

              img {
                display: inline;
              }
            }
            img {
              display: none;
            }
          }
        }
      }
      .show {
        display: none;
        gap: 4px;
        align-items: center;
        overflow: hidden;
        transform: translateX(-100%);
        z-index: -1;
        margin-top: 4px;
        /* 应用动画 */
        animation: leftShow 0.5s forwards;
        img {
          width: 10px;
          height: 12px;
          transform: rotate(180deg);
        }
      }
      @keyframes leftShow {
        0% {
          transform: translateX(-100%); /* 起始位置在左侧 */
          opacity: 1; /* 起始时透明 */
        }

        100% {
          transform: translateX(0); /* 最终位置在原位 */
          opacity: 1; /* 最终完全不透明 */
        }
      }
    }
    .contents {
      @apply flex w-full h-full;
    }
    .content {
      @apply grid gap-4 grid-cols-3  md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6;
      width: 100%;
      padding: 0px;
      height: 100%;
      // transition: transform 0.3s;
      // transform: translate3d(0px, 0px, 0px);
      // flex-wrap: nowrap;
      // overflow: hidden;
    }

    .cardList {
      // flex: 1 1 auto;
      // display: inline-block;

      position: relative;
      // width: calc(100vw / 9);

      color: #f5f5f5;
      // text-align: center;
      border-radius: 2px;
      // background-color: #5fb878;
      // margin-right: 16px;
      height: 100%;

      .cover {
        width: 100%;
        border-radius: 2px;
        object-fit: cover;
      }
      .imgs {
        // max-height: 400px;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .infolayer {
          position: absolute;
          height: 60px;
          width: 100%;
          bottom: 88px;
          background-image: linear-gradient(
            0deg,
            rgba(10, 12, 15, 0.8) 0%,
            rgba(10, 12, 15, 0.74) 4%,
            rgba(10, 12, 15, 0.59) 17%,
            rgba(10, 12, 15, 0.4) 34%,
            rgba(10, 12, 15, 0.21) 55%,
            rgba(10, 12, 15, 0.06) 78%,
            rgba(10, 12, 15, 0) 100%
          );
          @media (min-width: 1024px) and (max-width: 1280px) {
            bottom: 74px;
          }
          @media (min-width: 768px) and (max-width: 1024px) {
            bottom: 68px;
          }
          @media screen and (max-width: 768px) {
            bottom: 62px;
          }
        }
        .infolayerall {
          position: absolute;
          height: 60px;
          width: 100%;
          bottom: 50px;
          background-image: linear-gradient(
            0deg,
            rgba(10, 12, 15, 0.8) 0%,
            rgba(10, 12, 15, 0.74) 4%,
            rgba(10, 12, 15, 0.59) 17%,
            rgba(10, 12, 15, 0.4) 34%,
            rgba(10, 12, 15, 0.21) 55%,
            rgba(10, 12, 15, 0.06) 78%,
            rgba(10, 12, 15, 0) 100%
          );
          // @media (min-width: 1024px) and (max-width: 1280px) {
          //   bottom: 50px;
          // }
          // @media (min-width: 768px) and (max-width: 1024px) {
          //   bottom: 68px;
          // }
          // @media screen and (max-width: 768px) {
          //   bottom: 62px;
          // }
        }
        .img {
          border-radius: 4px;
          width: 100%;
          height: 100%;
          // height: $width  ;
        }
      }
      .largeCard {
        @apply absolute hidden  text-base text-white rounded  items-center justify-center;
        overflow: hidden;
        top: 0;
        transition: all 0.5s ease;
        background-color: #000;
        width: 100%;
        height: 100%;
        border-radius: 4px;

        &:hover {
          width: 130%;
          height: 110%;
          margin-top: -10%;
          margin-left: -15%;
          z-index: 2;
        }
        // text-align: center;
        .lgimg {
          height: 39%;
          width: 100%;
        }
        .lgbtn {
          position: absolute;

          bottom: 61%;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: right;
          .btnCollect {
            margin: 10px 10px;
            width: 36px;
            height: 36px;
            border-radius: 36px;
            background-color: rgba(125, 125, 125, 0.8);
            &:hover {
              background-color: rgba(155, 155, 155, 1);
            }
          }
          .mbtn {
            margin: 10px 10px;
            width: 36px;
            height: 36px;
            border-radius: 36px;
            margin: 10px 10px;
            background-color: #5fb878;
            &:hover {
              background-color: #6bd18a;
            }
          }
        }
        .lgbar {
          display: grid;
          gap: 4px;
          padding: 0 10px;
          color: rgba(255, 255, 255, 1);

          .scores {
            font-family: var(--rankFamily);
            min-height: 24px;
            display: flex;
            align-items: center;
            gap: 4px;
            font-size: 14px;
            .lgscore {
              color: #5fb878;

              // backdrop-filter: blur(2px);
              // border-radius: 0px;
            }
            .line {
              width: 2px;
              height: 46%;

              background-color: rgba(155, 155, 155, 0.8);
            }
          }
          & > h1 {
            &:hover {
              color: #5fb878;
            }
            margin-top: 12px;
            font-size: 16px;
          }
          .labels {
            display: flex;
            min-height: 18px;
            .label {
              padding: 0 4px;
              background-color: rgba(255, 255, 255, 0.6);
              opacity: 0.8;
              border-radius: 2px;
              margin-right: 4px;
              font-size: 12px;
              min-height: 18px;
              line-height: 18px;
            }
          }

          .descs {
            word-wrap: break-word;
            overflow-wrap: break-word;
            overflow: hidden;
            font-size: 12px;
            line-height: 20px;

            white-space: normal;
            // display: -webkit-box;
            // overflow: hidden;

            // -webkit-box-orient: vertical;
            // -webkit-line-clamp: 3;

            // text-overflow: ellipsis;
          }
        }
      }
      &:hover {
        .largeCard {
          display: block;
        }
      }
      .official {
        top: 0;
        right: 0px;
        width: 120px;
        position: absolute;
      }

      // 分数
      .score {
        bottom: 52px;
        color: #f93;
        z-index: 1;
        font-family: var(--rankFamily);
        font-size: 20px;
        position: absolute;
        left: 0px;
        // backdrop-filter: blur(2px);
        // border-radius: 0px;
        background: linear-gradient(rgba(0, 0, 0, 0), var(--bg-color));
        padding: 2px 8px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .addLove {
          @apply w-8 h-8;
        }
      }

      //收藏
      .addLove {
        @apply w-12 h-12;
        // backdrop-filter: blur(10px);
        // border-radius: 20px;
        // padding: 5px 8px;
        // & > img{
        //   @apply w-full h-full object-contain;
        // }
        // &:hover::after{
        //   @apply absolute text-sm px-5 left-2/4 text-white text-center whitespace-nowrap rounded;
        //   top:60px;
        //   padding-top: 10px;
        //   padding-bottom:10px;
        //   transform: translateX(-50%);
        //   background-color: #262626;
        //   content: attr(html-txt);
        // }
      }

      .name {
        padding: 10px 0px;
        height: 50px;
      }
      @media screen and (max-width: 768px) {
        .name {
          font-size: 14px;
        }
      }
      .desc {
        font-size: small;
        color: #666;
      }
    }
    // .content {
    //   @apply grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-6;
    //   width: 100%;
    //   padding: 0px;
    // }

    .cardList {
      position: relative;
      width: 100%;
      color: #f5f5f5;
      // text-align: center;

      .cover {
        width: 100%;
        border-radius: 6px;
        object-fit: cover;
      }
      &.imgs {
        // max-height: 400px;

        height: 100%;
        width: 100%;
        border-radius: 20px;
        .img {
          // width: 270px;
          // height: $width  ;
          height: 100%;
          width: 100%;
        }
        .largeCard {
          @apply absolute hidden text-base text-white rounded  items-center justify-center;
          overflow: hidden;

          top: 0;
          transition: all 0.5s ease;
          background-color: #f5f5f5;
          width: 100%;
          height: 100%;
          border-radius: 10px;
          &:hover {
            width: 120%;
            height: 120%;
            margin-top: -10%;
            margin-left: -10%;
            z-index: 2;
          }
          // text-align: center;
          .lgimg {
            height: 100%;
            width: 100%;
            filter: brightness(50%);
          }
          .lgbar {
            bottom: 2%;
            left: 4%;
            display: grid;
            gap: 8px;
            position: absolute;
            color: rgba(255, 255, 255, 1);

            .lgscore {
              color: #f93;
              font-family: var(--rankFamily);
              font-size: 20px;
              min-height: 24px;
              // backdrop-filter: blur(2px);
              // border-radius: 0px;
            }
            & > h1 {
              font-size: 22px;
            }
            .label {
              padding: 2px 4px;
              border: 2px solid rgba(255, 255, 255, 0.6);
              opacity: 0.8;
              border-radius: 2px;
              margin-right: 8px;
              font-size: 12px;
            }
            p {
              font-size: 12px;
            }
            .descs {
              word-wrap: break-word;
              overflow-wrap: break-word;
              overflow: hidden;
              font-size: 12px;
              line-height: 20px;
              // height: calc(100% / 20 - 40px);
              white-space: normal;
            }
          }
        }
        &:hover {
          .largeCard {
            display: block;
          }
        }
      }

      .official {
        top: 0;
        right: 0px;
        width: 120px;
        position: absolute;
      }

      // 分数
      .score {
        bottom: 52px;
        color: #f93;
        z-index: 1;
        font-family: var(--rankFamily);
        font-size: 20px;
        position: absolute;
        left: 0px;
        // backdrop-filter: blur(2px);
        // border-radius: 0px;
        background: linear-gradient(rgba(0, 0, 0, 0), var(--bg-color));
        padding: 2px 8px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .addLove {
          @apply w-8 h-8;
        }
      }

      //收藏
      .addLove {
        @apply w-12 h-12;
        // backdrop-filter: blur(10px);
        // border-radius: 20px;
        // padding: 5px 8px;
        // & > img{
        //   @apply w-full h-full object-contain;
        // }
        // &:hover::after{
        //   @apply absolute text-sm px-5 left-2/4 text-white text-center whitespace-nowrap rounded;
        //   top:60px;
        //   padding-top: 10px;
        //   padding-bottom:10px;
        //   transform: translateX(-50%);
        //   background-color: #262626;
        //   content: attr(html-txt);
        // }
      }

      .name {
        padding: 10px 0px;
      }
      .desc {
        font-size: small;
        color: #666;
      }
    }
  }

  .rightContainer {
    @apply lg:col-span-2;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    //  justify-content: center;
    align-items: center;
    border-radius: 10px;
    &:hover {
      .moduleName {
        .icon {
          transform: 0.5;
          width: 50px;
        }
      }
    }

    .moduleName {
      display: flex;
      align-items: center;
      // justify-content: center;
      color: #fff;
      font-size: x-large;
      padding: 20px 0;
      text-align: center;
      .icon {
        height: 36px;
        width: 36px;
      }
    }

    .content {
      // background-color: #000;
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      padding: 0px 0 0 20px;
      color: #fff;
      // border-radius: 10px;

      width: 100%;
      height: 100%;
      .item {
        display: flex;
        margin: 5px;
        align-items: center;
        // background-color: #ffb60c;
        // border-radius: 10px;
        padding: 0 20px;
        background-color: #5fb878;
        width: 100%;
        .hotIndex {
          @apply w-5 h-5 text-center leading-5 rounded;
          background-color: #757575;

          &.one {
            background-color: #f92828;
          }

          &.two {
            @apply bg-theme;
          }

          &.three {
            background-color: #ffb60c;
          }
        }
        .name {
          font-weight: bold;
          font-size: large;
          padding: 20px;
          &:hover {
            color: red;
          }
          .label {
            // padding: 2px 5px;
            background-color: #5fb878;
            text-align: center;
            color: #fff;
            font-size: small;
            padding: 2px 5px;
          }
        }
        .img {
          width: 60px;
          height: 80px;
          border-radius: 3px;
        }
      }
    }
  }
}
